@import "../../globals.scss";

.switch-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: none;
    background-color: $main-color1;
    color: white;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 3;
    
    .icon {
        width: 24px;
        height: 24px;
    }
    
    @media screen and (max-width: $hide-sidebar-break-point) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}