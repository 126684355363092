@import "../../globals.scss";

.about {
    padding: 60px 0px;

    @media screen and (max-width: calc($lg-break-point - 1px)) {
        display: flex;
        justify-content: center;
    }

    .about-container {
        padding: 0px 12px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        // @media screen and (min-width: $sm-break-point) {
        //     max-width: 540px;
        // }

        // @media screen and (min-width: $md-break-point) {
        //     max-width: 720px;
        // }

        // @media screen and (min-width: $lg-break-point) {
        //     max-width: 960px;
        // }

        // @media screen and (min-width: $xl-break-point) {
        //     max-width: 1140px;
        // }

        // @media screen and (min-width: $xxl-break-point) {
        //     max-width: 1320px;
        // }

        .section-title {
            font-size: 32px;
            font-family: $font1;
            font-weight: bold;
            margin-bottom: 20px;
            color: $main-color2;
        }

        .bar {
            width: 50px;
            height: 3px;
            background-color: $main-color1;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            font-family: $font3, sans-serif;
            color: #272829;
        }

        .info {
            display: flex;

            @media screen and (max-width: calc($lg-break-point - 1px)) {
                flex-direction: column;
            }
            

            .profile {
                flex: 1;
                padding: 0px 12px;

                img {
                    max-width: 100%;
                    height: auto;
                }

            }

            .details {
                flex: 2;
                padding: 0px 12px;
                display: flex;
                flex-direction: column;

                h3 {
                    font-size: 26px;
                    font-family: $font1, sans-serif;
                    color: $main-color2;
                    margin: 0px 0px 8px;
                }

                p {
                    margin: 0px 0px 16px;
                }

                .two-cols {
                    display: flex;
                    margin-bottom: 20px;

                    @media screen and (max-width: calc($lg-break-point - 1px)) {
                        flex-direction: column;
                        gap: 20px;
                    }
        
                    .left, .right {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
        
        
                        .info-item {
                            display: flex;
                            align-items: center;
        
                            .arrow {
                                width: 25px;
                                height: 25px;
                                color: $main-color1;
                            }
        
                            strong, span {
                                font-size: 16px;
                                font-family: $font3;
                            }

                            strong {
                                margin-right: 10px;
                            }

                        }
                    }

                }
            }
        } 

    }

}