@import "../../globals.scss";

.projects {
    padding: 60px 0px;
    width: 100%;
    background-color: $main-color8;
    @media screen and (max-width: calc($lg-break-point - 1px)) {
        display: flex;
        justify-content: center;
    }

    .projects-container {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0px 12px;
        width: 100%;

        // @media screen and (min-width: $sm-break-point) {
        //     max-width: 540px;
        // }

        // @media screen and (min-width: $md-break-point) {
        //     max-width: 720px;
        // }

        // @media screen and (min-width: $lg-break-point) {
        //     max-width: 960px;
        // }

        // @media screen and (min-width: $xl-break-point) {
        //     max-width: 1140px;
        // }

        // @media screen and (min-width: $xxl-break-point) {
        //     max-width: 1320px;
        // }

        .section-title {
            font-size: 32px;
            font-family: $font1;
            font-weight: bold;
            margin-bottom: 20px;
            color: $main-color2;
        }

        .bar {
            width: 50px;
            height: 3px;
            background-color: $main-color1;
            margin-bottom: 20px;
        }

        .project-board {
            width: 100%;
            padding: 0px 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            .project-item {
                width: 400px;
                display: flex;
                flex-direction: column;
                border-radius: 10px;
                border: none;
                overflow: hidden;
                background-color: white;
                -moz-box-shadow: 0px 0px 15px -2px #4E4E4E;
                -webkit-box-shadow: 0px 0px 15px -2px #4E4E4E; 
                box-shadow: 0px 0px 15px -2px #4E4E4E;

                @media screen and (max-width: calc($md-break-point - 1px)) {
                    width: 100%
                }

                .wallpaper {
                    width: 100%;
                    height: 200px;

                    .project-image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .body {
                    display: flex;
                    flex-direction: column;
                    padding: 5px 10px;
                    width: 100%;
                    padding: 10px 15px;
                    box-sizing: border-box;
                    gap: 10px;

                    h3 {
                        font-size: 24px;
                        font-family: $font2, sans-serif;
                        color: $main-color4;
                    }

                    p {
                        font-size: 16px;
                        font-family: $font3, sans-serif;
                        color: #272829;
                    }

                    .btn-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        button {
                            border: none;
                            border-radius: 5px;
                            background-color: $main-color1;
                            color: white;
                            padding: 5px 10px;
                            font-size: 16px;
                            font-weight: 600;
                            cursor: pointer;
                        }
                    }
                }
            }

            .project-item-2 {
                display: flex;
                align-items: center;
                padding: 10px 0px;
                border-bottom: 1px solid $main-color5;
                gap: 10px;

                .image-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid black;
                    border-radius: 10px;
                    min-width: 100px;
                    width: 100px;
                    min-height: 100px;
                    height: 100px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .body {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 5px;

                    h3 {
                        font-size: 18px;
                        font-weight: 400px;
                    }

                    p {
                        font-size: 18px;
                    }
                }
            }
        }

    }
}