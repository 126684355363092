$main-color1: #149ddd;
$main-color2: #173b6c;
$main-color3: #2c2f3f;
$main-color4: #040b14;
$main-color5: #a8a9b4;
$main-color6: #212431;
$main-color7: #dff3fc;
$main-color8: #f5f8fd;
$skill-title-color: #050d18;
$resume-item-border-left-color: #1f5297;
$font1: "Raleway";
$font2: "Poppins";
$font3: "Open Sans";
$hide-sidebar-break-point: 1199px;
$sm-break-point: 576px;
$md-break-point: 768px;
$lg-break-point: 992px;
$xl-break-point: 1200px;
$xxl-break-point: 1400px;