@import "./globals.scss";

.app {

    .container {
        display: flex;

        #main {
            width: 100%;
            height: 100vh;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .link {
            text-decoration: none;
            color: $main-color1;
        }
    }

}