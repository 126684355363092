@import "../../globals.scss";

.home {
    height: 100vh;
    width: 100%;
    background: url("../../../public/background.jpg") top center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;

    &::before {
        background: rgba(5, 13, 24, 0.3);
    }

    h1 {
        font-family: $font1;
        font-size: 64px;
    }

    .title {
        font-family: $font2;
        font-size: 26px;

        .dynamic-text {
            border-bottom: 3px solid $main-color1;
        }

        .ityped-cursor {
            opacity: 1;
            -webkit-animation: blink 0.3s infinite;
            -moz-animation: blink 0.3s infinite;
            animation: blink 0.3s infinite;
            animation-direction: alternate;
        }
         
        @keyframes blink {
            100% {
                opacity: 0;
            }
        }
         
        @-webkit-keyframes blink {
            100% {
                opacity: 0;
            }
        }
         
        @-moz-keyframes blink {
            100% {
                opacity: 0;
            }
        }
    }
}