@import "../../globals.scss";

.resume {
    padding: 60px 0px;
    width: 100%;
    background-color: white;

    @media screen and (max-width: calc($lg-break-point - 1px)) {
        display: flex;
        justify-content: center;
    }

    .resume-container {
        display: flex;
        flex-direction: column;
        padding: 0px 12px;

        // @media screen and (min-width: $sm-break-point) {
        //     max-width: 540px;
        // }

        // @media screen and (min-width: $md-break-point) {
        //     max-width: 720px;
        // }

        // @media screen and (min-width: $lg-break-point) {
        //     max-width: 960px;
        // }

        // @media screen and (min-width: $xl-break-point) {
        //     max-width: 1140px;
        // }

        // @media screen and (min-width: $xxl-break-point) {
        //     max-width: 1320px;
        // }

        p, ul, em {
            font-size: 16px;
            font-family: $font3, sans-serif;
            color: #272829;
        }

        .resume-header {
            display: flex;
            flex-direction: column;

            div {

                .bar {
                    width: 50px;
                    height: 3px;
                    background-color: $main-color1;
                    margin-bottom: 20px;
                }
            }
        }

        .resume-body {
            display: flex;
            flex-direction: column;
            
            @media screen and (max-width: calc($lg-break-point - 1px)) {
                flex-direction: column;
            }
            
        }

        .resume-container-left, .resume-container-right {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 0px 12px;
        }

        .section-title {
            font-size: 32px;
            font-family: $font1;
            font-weight: bold;
            margin-bottom: 20px;
            color: $main-color2;
        }

        .resume-title {
            font-size: 26px;
            font-family: $font1, sans-serif;
            color: #050d18;
            margin: 20px 0px;
        }

        .resume-item {
            display: flex;
            gap: 20px;
            border-left: 2px solid $resume-item-border-left-color;
            position: relative;
            padding: 0px 0px 20px 20px;
            margin-top: -2px;
            // flex-direction: column;
            .resume-item-left {
                min-width: 100px;
                width: 100px;


                img {
                    width: 100%;
                }

                @media screen and (max-width: 390px) {
                    min-width: 50px;
                    width: 50px;
                }
            }

            .resume-item-right {

                
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -9px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: white;
                    border: 2px solid #1f5297;
                }
                .time-range {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px 15px;
                    background-color: #e4edf9;
                    width: fit-content;
                    font-family: $font1, sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0px 0px 10px;
                }
                
                
                h4 {
                    text-transform: capitalize;
                    font-size: 18px;
                    font-family: $font2, sans-serif;
                    color: #050d18;
                    margin: 0px 0px 10px;
                }
                
                .location {
                    margin: 0px 0px 10px;
                }
                
                img {
                    width: 60px;
                    height: 80px;
                }
            }
        }
    }

}