@import "../../globals.scss";

.sidebar {
    height: 100vh;
    width: 300px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0px 15px;
    background-color: $main-color4;
    

    .top {
        display: flex;
        flex-direction: column;
        align-items: center;

        .profile-picture {
            width: 120px;
            height: 120px;
            border: solid 8px $main-color3;
            border-radius: 100%;
            margin: 15px 75px;
            object-fit: cover;
        }

        .name {
            font-family: $font2, sans-serif;
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            color: white;

            a {
                text-decoration: none;
                color: inherit;
            }
        }

        .social-links {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 16px;

            .item {
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                background-color: $main-color6;
                color: white;

                a {
                    text-decoration: none;
                    color: inherit;
                    
                    .social-icon {
                        width: 18px;
                        height: 18px;
                    }
                }

                &:hover {
                    background-color: $main-color1;
                    color: white;
                }

            }
        }

        .email {
            font-family: $font3;
            color: white;
            text-align: center;
            margin-top: 10px;
            transition: all 1s;
        }
    }
    
    .bottom {
        display: flex;
        flex-direction: column;

        .menu {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 12px 15px;
            cursor: pointer;
            color: $main-color5;
            font-family: $font3;
            font-size: 15px;
            text-decoration: none;

            .icon {
                width: 24px;
                height: 24px;
            }

            &.active, &:hover {
                color: white;

                .icon {
                    color: $main-color1;
                }
            }
        }
    }

    @media screen and (max-width: $hide-sidebar-break-point) {
        position: absolute;
        top: 0;
        left: -300px;
        transition: left 0.5s;
        //transition: position 0.5s;
        z-index: 3;

        &.open {
            left: 0;
            -webkit-scrollbar: disabled;
        }
    }
}